/* eslint-disable */
import React, { useRef, useEffect, useState } from "react";
import { useMessages } from "../contexts/MessageProvider";
import { useAuth } from "../contexts/AuthProvider";
import { useLocation } from "react-router-dom";

import ChatApi from "../services/chatAPIService";

import InputBox from "./InputBox";
import SuggestionButton from "./SuggestionButton";
import ChatMessages from "./ChatMessages";
import ContextBox from "./ContextBox";

import "./ChatWindow.css";
import "./Message.css";
import logo from "../images/logo.svg";

function ChatWindow({ chatId }) {
  const RESUMO_PACIENTE_CONTEXT = [
    "resumo_paciente",
    "Resumo do Paciente",
    "Você selecionou **Resumo do Paciente**. Envie o ID do paciente para darmos início à conversa.",
  ];
  const GESTAO_LEITOS_CONTEXT = [
    "gestao_leitos",
    "Gestão de Leitos",
    "Você selecionou **Gestão de Leitos**. Envie as informações do paciente para darmos início à conversa.",
  ];
  const TAXA_OCUPACAO_CONTEXT = [
    "taxa_ocupacao_leitos",
    "Taxa de Ocupação de Leitos",
    "Você selecionou **Taxa de Ocupação de Leitos**.",
  ];
  const DUVIDAS_GERAIS = [
    "duvidas_gerais",
    "Dúvidas Gerais",
    "Você selecionou **Dúvidas Gerais**. Envie seu questionamento para darmos início à conversa.",
  ];

  const chatWindowRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialContext = queryParams.get("context");

  const {
    messages,
    updateMessages,
    addMessage,
    isStreaming,
    lastStreamMessage,
    finishedStream,
  } = useMessages();
  const {
    sendPrompt,
    getChatHistory,
    getTitle,
    getContextValue,
    getDataTable,
    hasError,
    isPatientNotFound,
  } = ChatApi(useAuth().userToken);

  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [selectedContextValue, setSelectedContextValue] = useState(initialContext || "");
  const [newChatId, setNewChatId] = useState(chatId || "");
  const [hideInput, setHideInput] = useState(false);
  const [chatInfo, setChatInfo] = useState(null);
  const [newMessageInChatHistory, setNewMessageInChatHistory] = useState(false);
  const [chatInit, setChatInit] = useState(false);

  const handleChatSubmit = async (message, contextValue) => {
    if (loadingAnswer) return;

    setLoadingAnswer(true);

    // Se nenhum contexto foi definido, usar "Dúvidas Gerais"
    contextValue = selectedContextValue || contextValue || DUVIDAS_GERAIS[0];

    let prompt;

    if (contextValue === TAXA_OCUPACAO_CONTEXT[0]) {
      prompt = message;
    } else {
      prompt = `${contextValue}#${message}`;
      addMessage(message, "user");
    }

    try {
      const chatData = await sendPrompt(prompt, newChatId);
      updateMessages(chatData.chat.messages);
      setNewChatId(chatData.chat.id);
      setChatInfo(chatData.chat);
      if (chatId !== null) {
        setNewMessageInChatHistory(true);
      }
    } catch (error) {
      console.error("Error while fetching chat response:", error);
    } finally {
      setLoadingAnswer(false);
    }
  };

  const chatInitRef = useRef(false);

  const initSuggestionChat = async (contextValue) => {
    if (chatInitRef.current) return; // Impede execução dupla

    chatInitRef.current = true; // Atualiza imediatamente sem aguardar re-renderização

    setSelectedContextValue(contextValue);
    setLoadingAnswer(false);
    setChatInit(true); // Apenas para renderização correta

    if (contextValue === TAXA_OCUPACAO_CONTEXT[0]) {
      await handleChatSubmit(`${TAXA_OCUPACAO_CONTEXT[0]}#`, contextValue);
    }
  };

  useEffect(() => {
    if (initialContext && !chatInitRef.current) {
      initSuggestionChat(initialContext);
    }
  }, [initialContext]);

  const getContextMessage = () => {
    if (selectedContextValue === RESUMO_PACIENTE_CONTEXT[0]) {
      return RESUMO_PACIENTE_CONTEXT[2];
    } else if (selectedContextValue === GESTAO_LEITOS_CONTEXT[0]) {
      return GESTAO_LEITOS_CONTEXT[2];
    } else if (selectedContextValue === TAXA_OCUPACAO_CONTEXT[0]) {
      return TAXA_OCUPACAO_CONTEXT[2];
    } else {
      return DUVIDAS_GERAIS[2];
    }
  };

  const resumoPacienteChatFinished = () => {
    return (
      !loadingAnswer &&
      selectedContextValue === RESUMO_PACIENTE_CONTEXT[0] &&
      messages.length > 1 &&
      !hasError(chatInfo) &&
      !isPatientNotFound(chatInfo)
    );
  };

  const canHideInput = () => {
    if (selectedContextValue === TAXA_OCUPACAO_CONTEXT[0]) {
      return true;
    } else if (selectedContextValue === RESUMO_PACIENTE_CONTEXT[0]) {
      return resumoPacienteChatFinished();
    }
    return false;
  };

  const contextTitleMap = {
    resumo_paciente: "Resumo do Paciente",
    gestao_leitos: "Gestão de Leitos",
    taxa_ocupacao_leitos: "Taxa de Ocupação de Leitos",
    duvidas_gerais: "Dúvidas Gerais",
  };

  const getContextTitle = () => {
    return contextTitleMap[selectedContextValue] || "Dúvidas Gerais";
  }

  const scrollToBottom = (currentRef) => {
    if (currentRef.current) {
      currentRef.current.scrollTo({
        top: currentRef.current.scrollHeight
      });
    }
  };

  useEffect(() => {
    scrollToBottom(chatWindowRef);
  }, [messages, lastStreamMessage]);

  const getSelectChatHistory = async (chatId) => {
    try {
      const history = await getChatHistory(chatId);
      return history;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  };

  useEffect(() => {
    setHideInput(canHideInput());
  }, [canHideInput]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (chatId !== undefined && messages.length === 0) {
        try {
          const chatInfo = await getSelectChatHistory(chatId);
          setChatInfo(chatInfo);
          const storedMessages = chatInfo.messages;
          const context = chatInfo.context.toLowerCase();

          if (storedMessages) {
            updateMessages(storedMessages, true);
            setSelectedContextValue(context);
          }
        } catch (error) {
          console.error("Error fetching stored messages:", error);
        } finally {
          setLoadingMessages(false);
        }
      } else {
        setLoadingMessages(false);
      }
    };

    fetchMessages();
  }, [
    chatId,
    messages.length,
    updateMessages,
    getTitle,
    getContextValue,
    getChatHistory,
    getSelectChatHistory,
  ]);

  if (loadingMessages) {
    return null;
  }

  if (messages.length === 0 && !chatInit) {
    return (
      <div>
        <ContextBox contextTitle={getContextTitle()} />

        <div className="chat-main-content">
          <div className="chat-window empty-chat">
            <div className="title-content mb-3">
              <img src={logo} alt="Assistant Logo" className="assistant-logo" />
              <h2 className="assistant-name">Como posso ajudar?</h2>
            </div>

            {/* Caixa de entrada */}
            {!hideInput && (
              <InputBox
                beginInputBox={true}
                loadingAnswer={loadingAnswer || isStreaming}
                handleChatSubmit={handleChatSubmit}
              />
            )}

            <div className="suggestions">
              <SuggestionButton
                context={GESTAO_LEITOS_CONTEXT[1]}
                contextValue={GESTAO_LEITOS_CONTEXT[0]}
                message={GESTAO_LEITOS_CONTEXT[2]}
                initSuggestionChat={initSuggestionChat}
              />

              <SuggestionButton
                context={RESUMO_PACIENTE_CONTEXT[1]}
                contextValue={RESUMO_PACIENTE_CONTEXT[0]}
                message={RESUMO_PACIENTE_CONTEXT[2]}
                initSuggestionChat={initSuggestionChat}
              />

              <SuggestionButton
                context={TAXA_OCUPACAO_CONTEXT[1]}
                contextValue={TAXA_OCUPACAO_CONTEXT[0]}
                message={TAXA_OCUPACAO_CONTEXT[2]}
                initSuggestionChat={initSuggestionChat}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ContextBox contextTitle={getContextTitle()} />
      <div className="chat-main-content">
        <ChatMessages
          messages={messages}
          chatId={chatId}
          chatInfo={chatInfo}
          newMessageInChatHistory={newMessageInChatHistory}
          finishedStream={finishedStream}
          loadingAnswer={loadingAnswer}
          selectedContextValue={selectedContextValue}
          getDataTable={getDataTable}
          hasError={hasError}
          isPatientNotFound={isPatientNotFound}
          getContextMessage={getContextMessage}
          hideInput={hideInput}
          scrollToBottom={scrollToBottom}
        />
        {/* Caixa de entrada */}
        {!hideInput && (
          <InputBox
            beginInputBox={false}
            loadingAnswer={loadingAnswer || isStreaming}
            handleChatSubmit={handleChatSubmit}
          />
        )}
      </div>
    </div>
  );
}

export default ChatWindow;
