import React from "react";
import "./ContextBox.css";

const ContextBox = ({ contextTitle }) => {
  if (!contextTitle) return null; 

  return (
    <div className="context-box">
      <div>{contextTitle}</div>
    </div>
  );
};

export default ContextBox;
