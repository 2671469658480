import React, { useEffect, useState } from "react";
import ChatHistory from "./ChatHistory"; 
import ChatApi from "../services/chatAPIService";
import { useAuth } from "../contexts/AuthProvider";

import "./History.css";

function isToday(date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

function isYesterday(date) {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
}

function isThisMonth(date) {
  const today = new Date();
  return (
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

function History() {
  const { getChats } = ChatApi(useAuth().userToken);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const chatData = await getChats();
        setChats(chatData);
    
      } catch (error) {
        console.error("Error while fetching chat history:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChats();
  }, [getChats]);

  const chatsToday = chats.filter(chat => isToday(new Date(chat.date)));
  const chatsYesterday = chats.filter(chat => isYesterday(new Date(chat.date)));
  const chatsThisMonth = chats.filter(chat => isThisMonth(new Date(chat.date)) && !isToday(new Date(chat.date)) && !isYesterday(new Date(chat.date)));

  if (loading) {
    return <p>Loading chat history...</p>;
  }

  return (
    <div className="history-container">
      {chatsToday.length > 0 && (
        <>
          <div className="date-title">Hoje</div>
          {chatsToday.map((chat, key) => (
            <a key={key} href={`/chat/${chat.id}`}>
              <ChatHistory chat={chat} />
            </a>
          ))}
        </>
      )}

      {chatsYesterday.length > 0 && (
        <>
          <hr></hr>
          <div className="date-title">Ontem</div>
          {chatsYesterday.map((chat, key) => (
            <a key={key} href={`/chat/${chat.id}`}>
              <ChatHistory chat={chat} />
            </a>
          ))}
        </>
      )}
      
      {chatsThisMonth.length > 0 && (
        <>
          <hr></hr>
          <div className="date-title">Último mês</div>
          {chatsThisMonth.map((chat, key) => (
            <a key={key} href={`/chat/${chat.id}`}>
              <ChatHistory chat={chat} />
            </a>
          ))}
        </>
      )}

      {chats.length === 0 && (
        <p>Ainda não foram iniciadas conversas com o Assistente.</p>
      )}
    </div>
  );
}

export default History;