import React, { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import PendingIcon from '@mui/icons-material/Pending';

import './InputBox.css';

function InputBox({ loadingAnswer, handleChatSubmit, beginInputBox }) { 
  const [input, setInput] = useState('');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if(!loadingAnswer){
        handleSubmit(e);
      } 
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInput(''); 
    await handleChatSubmit(input);
  };

  return (
    <form className={beginInputBox ? "input-box" : " input-box chat-input-box"} onSubmit={handleSubmit}>
      <div className="input-container">
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          rows="3"
        />
        <button
          type="submit"
          className="send-button"
          disabled={loadingAnswer} 
        >
          {loadingAnswer ? <PendingIcon /> : <SendIcon />}
        </button>
      </div>
    </form>
  );
}

export default InputBox;
